import React from "react";
import { useParams } from "react-router-dom";
import { TransactionSchemaOutput, TransactionType } from "client/data-contracts";
import { selectIncomeList } from "components/Finances/Income/IncomeSlice";
import RegularTransactionsForm from "components/Finances/Transactions/Form/RegularTransactionsForm";
import TransferTransactionsForm from "components/Finances/Transactions/Form/TransferTransactionsForm";
import { useAppSelector } from "hooks";
import getById from "utils/crud";
import { DEFAULT_CURRENCY } from "utils/finances";

export default function TransactionsAdd(): React.JSX.Element {
  const { type, fromAccountId, toEntityId } = useParams();
  const incomeList = useAppSelector(selectIncomeList);

  if (type === "transfer") {
    return (
      <TransferTransactionsForm
        entity={
          {
            type: TransactionType.TRANSFER,
            account_id: fromAccountId,
            to_account_id: toEntityId,
          } as TransactionSchemaOutput
        }
      />
    );
  }
  if (type === "regular") {
    return (
      <RegularTransactionsForm
        entity={
          {
            type: TransactionType.REGULAR,
            account_id: fromAccountId,
            category_id: toEntityId,
          } as TransactionSchemaOutput
        }
      />
    );
  }
  if (type === "income") {
    const income = getById(incomeList, fromAccountId as string);
    return (
      <RegularTransactionsForm
        entity={
          {
            currency: DEFAULT_CURRENCY,
            from_income_id: fromAccountId,
            type: TransactionType.INCOME,
            account_id: toEntityId,
            from_income: {
              name: income.name,
            },
          } as TransactionSchemaOutput
        }
      />
    );
  }

  return <div>Unknown transaction type: {type}</div>;
}
