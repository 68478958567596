import React from "react";
import BookList from "components/Media/Book/BookList";
import { Route, Routes } from "react-router-dom";
import BookAdd from "components/Media/Book/BookAdd";
import BookView from "components/Media/Book/BookView";
import BooksStat from "./Book/BooksStat";

export default function Media(): React.JSX.Element {
  return (
    <Routes>
      <Route path="/books/" element={<BookList />} />
      <Route path="/books/add" element={<BookAdd />} />
      <Route path="/books/stat" element={<BooksStat />} />
      <Route path="/books/:bookId" element={<BookView />} />
    </Routes>
  );
}
