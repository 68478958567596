import React from "react";
import { BookSchemaOutput, BookSource, ExternalIdentifier } from "client/data-contracts";

function getSearchURL(source: BookSource, book: BookSchemaOutput): string {
  if (source === BookSource.Fantlab) {
    return `https://fantlab.ru/searchmain?searchstr=${book.stripped_title}`;
  }

  if (source === BookSource.AuthorToday) {
    return `https://author.today/search?category=works&q=${book.stripped_title}`;
  }

  if (source === BookSource.Goodreads) {
    return `https://www.goodreads.com/search?search_type=books&q=${book.stripped_title}`;
  }

  return "";
}

function getBookExternalURL(item: ExternalIdentifier): string {
  if (item.source_name === BookSource.Fantlab) {
    return `https://fantlab.ru/work${item.source_id}`;
  }

  if (item.source_name === BookSource.AuthorToday) {
    return `https://author.today/work/${item.source_id}`;
  }

  if (item.source_name === BookSource.Goodreads) {
    return `https://www.goodreads.com/book/show/${item.source_id}`;
  }

  return "";
}

interface Props {
  book: BookSchemaOutput;
  className?: string;
}

export default function BookExternalLinks({ book, className = "" }: Props): React.JSX.Element {
  return (
    <div className={`d-flex gap-2 ${className}`}>
      {Object.values(BookSource)
        .sort()
        .map((source) => {
          const item = book.external_links?.find((x) => x.source_name === source);
          return item ? (
            <a
              key={source}
              href={getBookExternalURL(item)}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-primary"
            >
              [{source[0]}]
            </a>
          ) : (
            <a
              key={source}
              href={getSearchURL(source, book)}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-secondary"
            >
              [{source[0]}]
            </a>
          );
        })}
    </div>
  );
}
