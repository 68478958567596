/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AuthRequestSchema */
export interface AuthRequestSchema {
  /** Username */
  username: string;
  /** Password */
  password: string;
}

/** BookIdentifier */
export interface BookIdentifier {
  /** Value */
  value: string;
  type: BookIdentifierType;
}

/** BookIdentifierInputSchema */
export interface BookIdentifierInputSchema {
  /** Isbn13 */
  isbn13?: string | null;
  /** Isbn10 */
  isbn10?: string | null;
  /** Asin */
  asin?: string | null;
}

/** BookIdentifierType */
export enum BookIdentifierType {
  Isbn13 = "isbn13",
  Isbn10 = "isbn10",
  Asin = "asin",
}

/** BookInputSchema */
export interface BookInputSchema {
  /** Url */
  url: string;
  /** Book Url */
  book_url?: string | null;
  /** Titles */
  titles?: LocalizedText[] | null;
  /** Authors */
  authors?: LocalizedText[] | null;
  series?: SeriesInputSchema | null;
  /** Description */
  description?: LocalizedText[] | null;
  volume?: BookVolumeInputSchema | null;
  /** Cover Url */
  cover_url?: string | null;
  /** Genres */
  genres?: string[] | null;
  /** Tags */
  tags?: string[] | null;
  identifiers?: BookIdentifierInputSchema | null;
  /** Original Language */
  original_language?: string | null;
  /** Date Published */
  date_published?: string | null;
}

/** BookReadingStatus */
export enum BookReadingStatus {
  InProgress = "in_progress",
  Done = "done",
  Dropped = "dropped",
}

/** BookSchema */
export interface BookSchemaInput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Titles */
  titles?: LocalizedText[] | null;
  /** Authors */
  authors?: LocalizedText[] | null;
  /** External Links */
  external_links?: ExternalIdentifier[] | null;
  series?: SeriesInput | null;
  /** Description */
  description?: LocalizedText[] | null;
  /** Genres */
  genres?: string[] | null;
  /** Tags */
  tags?: string[] | null;
  /** Cover Urls */
  cover_urls?: string[] | null;
  /** Volume */
  volume?: BookVolume[] | null;
  /** Identifiers */
  identifiers?: BookIdentifier[] | null;
  /** Original Language */
  original_language?: string | null;
  /** Date Published */
  date_published?: string | null;
  /** Reading History */
  reading_history?: ReadingHistory[] | null;
}

/** BookSchema */
export interface BookSchemaOutput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Titles */
  titles?: LocalizedText[] | null;
  /** Authors */
  authors?: LocalizedText[] | null;
  /** External Links */
  external_links?: ExternalIdentifier[] | null;
  series?: SeriesOutput | null;
  /** Description */
  description?: LocalizedText[] | null;
  /** Genres */
  genres?: string[] | null;
  /** Tags */
  tags?: string[] | null;
  /** Cover Urls */
  cover_urls?: string[] | null;
  /** Volume */
  volume?: BookVolume[] | null;
  /** Identifiers */
  identifiers?: BookIdentifier[] | null;
  /** Original Language */
  original_language?: string | null;
  /** Date Published */
  date_published?: string | null;
  /** Reading History */
  reading_history?: ReadingHistory[] | null;
  /** Stripped Title */
  stripped_title: string;
  /** Main Title */
  main_title: string;
  /** Other Titles */
  other_titles: string[];
  /** Goodreads Star */
  goodreads_star: number;
}

/** BookSource */
export enum BookSource {
  Goodreads = "goodreads",
  Fantlab = "fantlab",
  AuthorToday = "author.today",
}

/** BookStatsSchema */
export interface BookStatsSchema {
  /** Books Read Per Year */
  books_read_per_year: Record<string, number>;
}

/** BookVolume */
export interface BookVolume {
  /** Value */
  value: number;
  type: BookVolumeType;
}

/** BookVolumeInputSchema */
export interface BookVolumeInputSchema {
  /** Number Of Symbols */
  number_of_symbols?: number | null;
  /** Author Pages */
  author_pages?: number | null;
  /** Pages */
  pages?: number | null;
}

/** BookVolumeType */
export enum BookVolumeType {
  NumberOfSymbols = "number_of_symbols",
  AuthorPages = "author_pages",
  Pages = "pages",
}

/** Currency */
export enum Currency {
  EUR = "EUR",
  HKD = "HKD",
  KRW = "KRW",
  RUB = "RUB",
  THB = "THB",
  USD = "USD",
}

/** CurrencyExchangeRateListSchema */
export interface CurrencyExchangeRateListSchema {
  /** Items */
  items: CurrencyExchangeRateSchema[];
  /** Page */
  page: number;
  /** Per Page */
  per_page: number;
  /** Count */
  count: number;
}

/** CurrencyExchangeRateSchema */
export interface CurrencyExchangeRateSchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  from_currency: Currency;
  to_currency: Currency;
  /** Rate */
  rate: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
}

/** DepositAccountListSchema */
export interface DepositAccountListSchema {
  /** Accounts */
  accounts: DepositAccountSchema[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  income_stat: MoneyStat;
}

/** DepositAccountSchema */
export interface DepositAccountSchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
  /** Percent */
  percent: number;
  /** Amount */
  amount: number;
  currency: Currency;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Account Id */
  account_id: string;
  /** Approximate Total Income */
  approximate_total_income?: number | null;
  account?: FinanceAccount | null;
}

/** ExternalIdentifier */
export interface ExternalIdentifier {
  source_name: BookSource;
  /** Source Id */
  source_id: string;
}

/** FinanceAccount */
export interface FinanceAccount {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  currency: Currency;
  account_type: FinanceAccountType;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Display Level
   * @default 0
   */
  display_level?: number;
  /**
   * Balance
   * @default 0
   */
  balance?: number;
  /**
   * Order
   * @default 0
   */
  order?: number;
}

/** FinanceAccountSchema */
export interface FinanceAccountSchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  currency: Currency;
  account_type: FinanceAccountType;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Display Level
   * @default 0
   */
  display_level?: number;
  /**
   * Balance
   * @default 0
   */
  balance?: number;
  /**
   * Order
   * @default 0
   */
  order?: number;
}

/** FinanceAccountType */
export enum FinanceAccountType {
  BANK = "BANK",
  CASH = "CASH",
  INVESTING = "INVESTING",
  OTHER = "OTHER",
}

/** FinanceCategory */
export interface FinanceCategory {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Order
   * @default 0
   */
  order?: number;
  category_type?: FinanceCategoryType | null;
}

/** FinanceCategoryListSchema */
export interface FinanceCategoryListSchema {
  /** Categories */
  categories: FinanceCategorySchema[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  monthly_spending_stat: MoneyStat;
}

/** FinanceCategorySchema */
export interface FinanceCategorySchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Order
   * @default 0
   */
  order?: number;
  category_type?: FinanceCategoryType | null;
  /**
   * Amount
   * @default 0
   */
  amount?: number;
}

/** FinanceCategoryType */
export enum FinanceCategoryType {
  PRODUCTS = "PRODUCTS",
  RESTAURANTS = "RESTAURANTS",
  TRANSPORT = "TRANSPORT",
  SERVICES = "SERVICES",
  ENTERTAINMENT = "ENTERTAINMENT",
  HOUSE = "HOUSE",
  SHOPPING = "SHOPPING",
  OTHER = "OTHER",
}

/** FinanceGroupIncomeAndExpensesStatisticsSchema */
export interface FinanceGroupIncomeAndExpensesStatisticsSchema {
  /** Income */
  income: IncomeBySourceSchema[];
  /** Expenses */
  expenses: object[];
}

/** FinanceGroupSchema */
export interface FinanceGroupSchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /** Start Date */
  start_date?: string | null;
  /** End Date */
  end_date?: string | null;
  group_type: FinanceGroupType;
  /** Sort Field */
  sort_field?: string | null;
  /** Associated Transaction Tags */
  associated_transaction_tags?: TransactionTag[] | null;
  statistics?: FinanceGroupStatistics | null;
}

/** FinanceGroupStatistics */
export interface FinanceGroupStatistics {
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  all: MoneyStat;
  /** Categories */
  categories: Record<string, MoneyStat>;
  /** Comments */
  comments: Record<string, MoneyStat>;
  /** Tags */
  tags: Record<string, MoneyStat>;
  /** Income */
  income: Record<string, MoneyStat>;
  /**
   * First Transaction Date
   * @format date-time
   */
  first_transaction_date: string;
  /**
   * Last Transaction Date
   * @format date-time
   */
  last_transaction_date: string;
}

/** FinanceGroupType */
export enum FinanceGroupType {
  MONTHLY_EXPENSES = "MONTHLY_EXPENSES",
  MONTHLY_INCOME = "MONTHLY_INCOME",
  PERIODICAL = "PERIODICAL",
  DATA_BASED = "DATA_BASED",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Income */
export interface Income {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Owner */
  owner?: string | null;
  /** Associated Account Id */
  associated_account_id: string;
}

/** IncomeBySourceSchema */
export interface IncomeBySourceSchema {
  /** Date */
  date: string;
  /** Value */
  value: number;
  /** Owner */
  owner?: string | null;
}

/** IncomeListSchema */
export interface IncomeListSchema {
  /** Income List */
  income_list: IncomeSchemaOutput[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  monthly_earnings_stat: MoneyStat;
}

/** IncomeSchema */
export interface IncomeSchemaInput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Owner */
  owner?: string | null;
  /** Associated Account Id */
  associated_account_id: string;
  associated_account?: FinanceAccount | null;
  /**
   * Earnings
   * @default 0
   */
  earnings?: number;
  earnings_currency?: Currency | null;
}

/** IncomeSchema */
export interface IncomeSchemaOutput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Owner */
  owner?: string | null;
  /** Associated Account Id */
  associated_account_id: string;
  associated_account?: FinanceAccount | null;
  /**
   * Earnings
   * @default 0
   */
  earnings?: number;
  earnings_currency?: Currency | null;
}

/** LocalizedText */
export interface LocalizedText {
  /** Name */
  name: string;
  /** Language */
  language: string;
}

/**
 * MoneyStat
 * It is an obj for the money statistic.
 * It contains approximate values of earning/spending in default currency.
 * And it contains exact earning/spending amount in all currencies
 */
export interface MoneyStat {
  /** Default Currency Amount */
  default_currency_amount: number;
  /** All Currencies Amount */
  all_currencies_amount: Record<string, number>;
}

/** ReadingHistory */
export interface ReadingHistory {
  /**
   * Date Start
   * @format date-time
   */
  date_start: string;
  /** Date End */
  date_end?: string | null;
  /** Score */
  score?: number | null;
  status: BookReadingStatus;
  /** Comment */
  comment?: string | null;
}

/** Series */
export interface SeriesInput {
  /** Titles */
  titles?: LocalizedText[] | null;
  /** External Links */
  external_links?: ExternalIdentifier[] | null;
  /** Position */
  position?: number | null;
}

/** Series */
export interface SeriesOutput {
  /** Titles */
  titles?: LocalizedText[] | null;
  /** External Links */
  external_links?: ExternalIdentifier[] | null;
  /** Position */
  position?: number | null;
}

/** SeriesInputSchema */
export interface SeriesInputSchema {
  /** Name */
  name: string;
  /** Language */
  language: string;
  /** Url */
  url: string;
  /** Position */
  position?: number | null;
}

/** TokenSchema */
export interface TokenSchema {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
}

/** TransactionSchema */
export interface TransactionSchemaInput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Amount */
  amount: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
  type: TransactionType;
  /** Tags */
  tags?: TransactionTag[] | null;
  /** Comment */
  comment?: string | null;
  /** Equivalent Amount In Usd */
  equivalent_amount_in_usd?: number | null;
  /** Account Id */
  account_id: string;
  /** Category Id */
  category_id?: string | null;
  /** From Income Id */
  from_income_id?: string | null;
  /** To Account Id */
  to_account_id?: string | null;
  to_currency?: Currency | null;
  /** To Amount */
  to_amount?: number | null;
  currency: Currency;
  from_income?: Income | null;
  account?: FinanceAccount | null;
  to_account?: FinanceAccount | null;
  category?: FinanceCategory | null;
}

/** TransactionSchema */
export interface TransactionSchemaOutput {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Amount */
  amount: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
  type: TransactionType;
  /** Tags */
  tags?: TransactionTag[] | null;
  /** Comment */
  comment?: string | null;
  /** Equivalent Amount In Usd */
  equivalent_amount_in_usd?: number | null;
  /** Account Id */
  account_id: string;
  /** Category Id */
  category_id?: string | null;
  /** From Income Id */
  from_income_id?: string | null;
  /** To Account Id */
  to_account_id?: string | null;
  to_currency?: Currency | null;
  /** To Amount */
  to_amount?: number | null;
  currency: Currency;
  from_income?: Income | null;
  account?: FinanceAccount | null;
  to_account?: FinanceAccount | null;
  category?: FinanceCategory | null;
}

/** TransactionTag */
export interface TransactionTag {
  /** Name */
  name: string;
}

/** TransactionType */
export enum TransactionType {
  REGULAR = "REGULAR",
  INCOME = "INCOME",
  TRANSFER = "TRANSFER",
}

/** UserSchema */
export interface UserSchema {
  /** Id */
  id?: string | null;
  /** Updated */
  updated?: string | null;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object | null;
  /** Username */
  username: string;
  /** Email */
  email: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}
