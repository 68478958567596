import React, { useEffect, useState } from "react";
import api from "utils/api";
import { BookSchemaOutput, BookSource } from "client/data-contracts";
import { displayDate } from "utils/date";
import { Link, useSearchParams } from "react-router-dom";
import BookExternalLinks from "components/Media/Book/BookExternalLinks";

export default function BookList(): React.JSX.Element {
  const [books, setBooks] = useState<BookSchemaOutput[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [emptySource, setEmptySource] = useState<BookSource | null>(
    (searchParams.get("emptySource") as BookSource) || null
  );
  const [withSource, setWithSource] = useState<BookSource | null>(
    (searchParams.get("withSource") as BookSource) || null
  );

  const sourceOptions = [
    <option key="none" value="">
      Select source
    </option>,
    ...Object.values(BookSource).map((source) => (
      <option key={source} value={source}>
        {source}
      </option>
    )),
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await api.books.getListOfBooks({
          with_empty_source: emptySource || undefined,
          with_source: withSource || undefined,
        });
        setBooks(response.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [emptySource, withSource]);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12 col-lg-12 d-flex">
          <div>
            <div className="d-flex align-items-center">
              <h3 data-test="books-list-header" className="mb-0 me-2">
                Books
              </h3>
              <Link to="/media/books/stat" className="btn btn-sm btn-outline-primary">
                Statistics
              </Link>
            </div>
            <div className="text-secondary small">({books.length} and counting)</div>
          </div>

          <Link
            to="/media/books/add"
            className="ms-auto btn btn-sm btn-outline-success mt-1"
            style={{ height: "31px" }}
          >
            Add a book
          </Link>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="d-flex align-items-center gap-3">
            <div>
              <label className="me-2">Find with empty source:</label>
              <select
                className="form-select form-select-sm"
                value={emptySource || ""}
                onChange={(e) => {
                  const value = e.target.value ? (e.target.value as BookSource) : null;
                  setEmptySource(value);
                  setWithSource(null);
                  setSearchParams(value ? { emptySource: value } : {}, { replace: true });
                }}
                data-test="book-empty-source-filter"
              >
                {sourceOptions}
              </select>
            </div>
            <div>
              <label className="me-2">Find with source:</label>
              <select
                className="form-select form-select-sm"
                value={withSource || ""}
                onChange={(e) => {
                  const value = e.target.value ? (e.target.value as BookSource) : null;
                  setWithSource(value);
                  setEmptySource(null);
                  setSearchParams(value ? { withSource: value } : {}, { replace: true });
                }}
                data-test="book-with-source-filter"
              >
                {sourceOptions}
              </select>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center my-5">Loading...</div>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {books.map((book) => (
              <tr key={book.id}>
                <td>
                  <Link to={`/media/books/${book.id}`} className="text-decoration-none">
                    {book.main_title}{" "}
                    <span className="text-secondary small">{book.other_titles.join(";")}</span> <br />
                  </Link>
                  <BookExternalLinks book={book} />
                </td>
                <td>
                  {book.reading_history?.[0]?.status} <br />
                  {book.reading_history?.[0]?.score}
                </td>
                <td>
                  {displayDate(book.reading_history?.[0]?.date_start)} <br />
                  {displayDate(book.reading_history?.[0]?.date_end)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
