import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TransactionSchemaOutput } from "client/data-contracts";
import type { RootState } from "store";

interface TransactionsState {
  transactions: TransactionSchemaOutput[];
  transactionsOffset: number;
  latestSearchQuery: string;
}

const initialState: TransactionsState = {
  transactions: [],
  transactionsOffset: 0,
  latestSearchQuery: "",
};

const sortFunction = (a: TransactionSchemaOutput, b: TransactionSchemaOutput) => {
  if (a.date === b.date) {
    return (a.updated || 0) > (b.updated || 0) ? -1 : 1;
  }
  return (a.date || 0) > (b.date || 0) ? -1 : 1;
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    loadTransactions: (state, action: PayloadAction<TransactionSchemaOutput[]>) => {
      state.transactions = action.payload;
    },
    deleteTransaction: (state, action: PayloadAction<TransactionSchemaOutput>) => {
      state.transactions = state.transactions.filter((f) => f.id !== action.payload.id);
    },
    updateTransaction: (state, action: PayloadAction<TransactionSchemaOutput>) => {
      state.transactions = state.transactions
        .map((item) => (item.id === action.payload.id ? action.payload : item))
        .sort(sortFunction);
    },
    createTransaction: (state, action: PayloadAction<TransactionSchemaOutput>) => {
      state.transactions = [...state.transactions, action.payload].sort(sortFunction);
    },
    setTransactionsOffset: (state, action: PayloadAction<number>) => {
      state.transactionsOffset = action.payload;
    },
    setLatestSearchQuery: (state, action: PayloadAction<string>) => {
      state.latestSearchQuery = action.payload;
    },
  },
});

export const {
  loadTransactions,
  createTransaction,
  deleteTransaction,
  updateTransaction,
  setTransactionsOffset,
  setLatestSearchQuery,
} = transactionsSlice.actions;

export const selectTransactions = (state: RootState): TransactionSchemaOutput[] =>
  state.transactions.transactions;
export const selectTransactionsOffset = (state: RootState): number => state.transactions.transactionsOffset;
export const selectLatestSearchQuery = (state: RootState): string => state.transactions.latestSearchQuery;

export default transactionsSlice.reducer;
