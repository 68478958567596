import React, { useState } from "react";
import { BookReadingStatus, BookSchemaOutput, ReadingHistory } from "client/data-contracts";
import { Nullish } from "utils/base";
import { AxiosError } from "axios";
import api from "utils/api";
import { GOODREADS_USERNAME } from "utils/media/book";
import { displayDate } from "utils/date";

function getGoodreadsReviewURL(book: BookSchemaOutput): string {
  const score = book.goodreads_star;
  const dateStart = displayDate(book.reading_history?.[0]?.date_start);
  const dateEnd = displayDate(book.reading_history?.[0]?.date_end);
  const title = book.stripped_title;
  return `https://www.goodreads.com/review/list/${GOODREADS_USERNAME}?search[query]=${title}&dateStart=${dateStart}&dateEnd=${dateEnd}&score=${score}`;
}

interface BookReadingHistoryProps {
  book: BookSchemaOutput;
  setBook: (book: BookSchemaOutput) => void;
  setRequestError: (error: Nullish<AxiosError>) => void;
}

export default function BookReadingHistory({
  book,
  setRequestError,
  setBook,
}: BookReadingHistoryProps): React.JSX.Element | null {
  const [score, setScore] = useState<number>(5);
  const [status, setStatus] = useState<BookReadingStatus>(BookReadingStatus.Done);
  const [comment, setComment] = useState<string>("");

  if (book.reading_history?.[0].date_end) {
    return (
      <div className="alert alert-info">
        <p>Score for this book: {book.reading_history?.[0].score}.</p>
        <a href={getGoodreadsReviewURL(book)} target="_blank" rel="noreferrer">
          Set goodreads score
        </a>
      </div>
    );
  }

  async function finishReadingHandler(event: React.FormEvent) {
    event.preventDefault();
    try {
      const readingHistory = book.reading_history?.[0] as ReadingHistory;
      readingHistory.score = score;
      readingHistory.comment = comment;
      readingHistory.status = status;
      const response = await api.books.finishReadingBook(book.id as string, readingHistory);
      setBook(response.data);
    } catch (error) {
      setRequestError(error as AxiosError);
    }
  }

  return (
    <form onSubmit={finishReadingHandler}>
      <h4>Score this book</h4>
      <div className="mb-3">
        <label htmlFor="score" className="form-label">
          Score (1-10)
        </label>
        <select
          className="form-select"
          id="score"
          value={score}
          onChange={(e) => setScore(Number(e.target.value))}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="score" className="form-label">
          Status
        </label>
        <select
          className="form-select"
          id="status"
          value={status}
          onChange={(e) => setStatus(e.target.value as BookReadingStatus)}
        >
          {[BookReadingStatus.Done, BookReadingStatus.Dropped].map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="comment" className="form-label">
          Comment
        </label>
        <textarea
          className="form-control"
          id="comment"
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="text-end">
        <button type="submit" className="btn btn-primary">
          Finish reading
        </button>
      </div>
    </form>
  );
}
