import React, { useEffect, useState } from "react";
import api from "utils/api";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BooksStat(): React.JSX.Element {
  const [statsData, setStatsData] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    (async () => {
      const response = await api.books.getBooksStatistics();
      setStatsData(response.data.books_read_per_year);
    })();
  }, []);

  const sortedData = Object.keys(statsData)
    .sort()
    .map((year) => statsData[year]);
  const minValue = Math.min(...sortedData);
  const maxValue = Math.max(...sortedData);

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          stepSize: 1,
        },
        min: minValue - 1,
        max: maxValue + 5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Books read by year",
      },
      datalabels: {
        anchor: "end" as const,
        align: "right" as const,
        offset: 4,
        color: "#666",
        font: {
          weight: "bold" as const,
        },
      },
    },
  };

  const data = {
    labels: Object.keys(statsData).sort(),
    datasets: [
      {
        label: "Number of Books",
        data: sortedData,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 1,
        borderRadius: 4,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
      },
    ],
  };

  return (
    <div>
      <h3>Books stat</h3>
      {Object.keys(statsData).length > 0 && (
        <div style={{ height: "400px", width: "100%" }}>
          <Bar options={options} data={data} />
        </div>
      )}
    </div>
  );
}
